<template>
  <div id="course">
    <!-- 课程详情 -->
    <div class="banner">
      <h1>{{ course.title }}</h1>
      <div class="pc">
        <p>
          专题：<slot v-if="course.venueName && course.venueName.name">{{
            course.venueName.name
          }}</slot>
        </p>
        <p>
          会议地点：<slot v-if="course.venueName && course.venueName.address">{{
            course.venueName.address
          }}</slot>
        </p>
        <p>
          案例来源：<slot v-if="course.venueName && course.venueName.company">{{
            course.lecturer[0].company
          }}</slot>
        </p>
        <p>
          分享时间：<slot v-if="course.venueName && course.venueName.date">{{
            course.venueName.date
          }}</slot>
        </p>
      </div>
      <div class="mob">
        <p>
          案例来源：<slot
            v-if="
              course.lecturer &&
              course.lecturer[0] &&
              course.lecturer[0].company
            "
            >{{ course.lecturer[0].company }}</slot
          >
        </p>
        <p>
          会议地点：<slot v-if="course.venueName && course.venueName.address">{{
            course.venueName.address
          }}</slot>
        </p>
        <p>
          分享时间：<slot v-if="course.venueName && course.venueName.date">{{
            course.venueName.date
          }}</slot>
        </p>
      </div>
    </div>
    <div class="banner_bottom">
      <slot v-if="course.lecturer && course.lecturer.length>0">
        <div class="con" v-for="(lecturer, index) in course.lecturer" :key="'course'+course.id+'_lecturer'+index">
          <router-link
            :to="{
              path: '/2022sz/teacher',
              name: 'TwoSZTeacher',
              query: { id: lecturer.id },
            }"
          >
            <div
              class="img"
              :style="
                'background:url(' +
                lecturer.thumbs +
                ') no-repeat center center;'
              "
            >
              <img :src="lecturer.thumbs" alt="">
            </div>
          </router-link>
          <div class="text">
            <p class="title">
              <router-link
                :to="{
                  path: '/2022sz/teacher',
                  name: 'TwoSZTeacher',
                  query: { id: lecturer.id },
                }"
                >{{ lecturer.name }}</router-link
              >
              <span
                v-if="
                  course.companythumbs != 0 &&
                  course.companythumbs != '' &&
                  course.companythumbs != null
                "
              >
                | <img :src="course.companythumbs" />
                <!-- <span>{{lecturer.company}}</span> -->
              </span>
            </p>
            <p class="desc">
              <slot v-if="lecturer.position">
                {{ lecturer.position }}
              </slot>
            </p>
            <p
              class="desc"
              v-if="lecturer.desc"
              v-html="lecturer.desc"
            >
              <!-- {{lecturer.desc}} -->
            </p>
          </div>
        </div>
      </slot>
    </div>
    <div class="course">
      <div class="con">
        <h2>课程概要</h2>
        <p v-html="course.desc"></p>
        <h2>听众收益</h2>
        <p v-html="course.profit"></p>
      </div>
    </div>
    <div class="other">
      <div class="con">
        <h1>相关案例</h1>
        <div class="case_con">
          <SelectedCaseNew :caseList="course.related"></SelectedCaseNew>
        </div>
        <div class="clearboth"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { sid, getScheduleDetail } from "@/utils/api.js";

export default {
  name: "Course",
  components: {
    SelectedCaseNew:()=> import("@/components/SelectedCaseNew"),
  },
  data() {
    return {
      params: "",
      course: "",
      metaInfo: {
        seoTitle: "",
        seoKeyword: "",
        seoDescription: "",
      },
    };
  },
  metaInfo() {
    return {
      title: this.metaInfo.seoTitle,
      meta: [
        {
          name: "keywords",
          content: this.metaInfo.seoKeyword,
        },
        { name: "description", content: this.metaInfo.seoDescription },
      ],
    };
  },
  methods: {
    getCourse: function () {
      this.$http
        .jsonp(getScheduleDetail + sid(this.global.year) + "/" + this.params.id)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          // console.log(data);
          if (data.errno == 0) {
            // console.log("course data: ",data.data);
            this.course = data.data;
            // this.course.related[].companythumbs'
            if (data.data.seo !== null && !Array.isArray(data.data.seo)) {
              this.metaInfo = data.data.seo;
            }
            if (this.metaInfo.seoTitle === "") {
              this.metaInfo.seoTitle =
                data.data.title + "-  GIAC 全球互联网架构大会";
            }
            for (let r = 0; r < data.data.related.length; r++) {
              // 相关案例logo
              this.$http
                .jsonp(
                  getScheduleDetail +
                    sid(this.global.year) +
                    "/" +
                    this.params.id +
                    data.data.related[r].id
                )
                .then((res) => {
                  return res.json();
                })
                .then((res) => {
                  if (res.errno == 0) {
                    this.course.related[r].companythumbs =
                      res.data.companythumbs;
                    // console.log("logo: ",this.course);
                  }
                });
            }
            // console.log("cours/1e: ",this.course);
          }
        });
    },
  },
  created() {
    this.params = this.$route.query;
    // this.getCourse();
  },
  mounted() {
    this.getCourse();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.params = this.$route.query;
        this.getCourse();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:visited {
  color: #337ab7;
  text-decoration: none;
}
// div
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
b,
i,
em,
div {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

#course {
  // margin-top: 135px;
  text-align: left;
  .banner {
    // margin-top: 135px;
    width: 100%;
    height: 305px;
    background: #fafbfc;
    text-align: center;
    h1 {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: PingFang SC Bold;
      font-weight: bold;
      color: #003177;
      padding-top: 80px;
      padding-bottom: 40px;
    }
    div {
      max-width: 725px;
      margin: 0 auto;
      p {
        width: 50%;
        float: left;
        font-size: 18px;
        font-family: PingFang SC Regular;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        padding-top: 12px;
      }
    }
  }
  .banner_bottom {
    width: 100%;
    // height: 328px;
    padding-top: 52px;
    padding-bottom: 65px;
    .con {
      max-width: 1110px;
      margin: 0 auto;
      padding-top: 43px;
      .img {
        width: 193px;
        height: 211px;
        margin-right: 60px;
        float: left;
        border-radius: 7px;
        background-size: 100% 100% !important;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .text {
        padding: 20px 0;
        .title,
        .title * {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #212121;
          line-height: 22px;
          // span {
          //   font-weight: bold;
          //   color: #de1b28;
          // }
          img {
            height: 34px;
            line-height: 34px;
            margin-left: 3px;
            margin-top: -5px;
          }
        }
        .desc {
          font-size: 16px;
          font-family: PingFang SC Regular;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
        }
        .desc:nth-of-type(2) {
          padding: 22px 0;
          margin-top: 5px;
        }
        .desc:nth-of-type(3) {
          line-height: 30px;
        }
      }
    }
  }
  .course {
    width: 100%;
    padding-top: 66px;
    padding-bottom: 100px;
    background: #fafbfc;
    .con {
      max-width: 1142px;
      margin: 0 auto;
      background: #ffffff;
      border-left: 8px solid #003177;
      padding: 34px 66px 50px 50px;
      h2 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #131313;
        line-height: 29px;
        padding-bottom: 32px;
      }
      h2:nth-of-type(2) {
        padding-top: 43px;
      }
      p {
        // white-space: pre-line;
        font-size: 16px;
        font-family: PingFang SC Regular;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
      }
    }
  }
  .other {
    width: 100%;
    background: #ffffff;
    padding-top: 86px;
    .con {
      // max-width: 1225px;
      // max-width: 1250px;
      margin: 0 auto;
      h1 {
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #003177;
        padding-bottom: 50.5px;
      }
      .case_con {
        width: 100%;
        // max-width: 1225px;
        margin: 0 auto;
        ul {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 43px;
          margin-bottom: 0;
          a:hover {
            box-shadow: 0px 0px 8px 0px rgba(72, 75, 77, 0.1);
          }
          a {
            display: flex;
            flex-direction: column-reverse;
            padding: 22px 22px 21px 20px;
            margin: 12.5px;
            text-decoration: none;
            text-align: left;
            background: #ffffff;
            border: 1px solid #d6dce3;
            .case-title {
              width: 305px;
              padding-left: 7px;
              padding-bottom: 10px;
              line-height: 25px;
              font-weight: 500;
              font-size: 18px;
              font-family: PingFang SC;
              color: #003177;
            }
            .content {
              border-top: 1px solid #d6dce3;
              padding-top: 36px;
              padding-left: 9px;
              width: 340px;
              img {
                width: 69px;
                height: 13px;
              }
              .name {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #484848;
                margin-bottom: 6px;
              }
              .company {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                color: #3e3a39;
                line-height: 16px;
              }
            }
          }
          a:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.clearboth {
  clear: both;
  float: none !important;
  width: 0 !important;
  height: 0 !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.pc {
  display: block;
}
.mob {
  display: none;
}

// @media (max-width: 1023px) {
//   #course {
//     // margin-top: 86px!important;
//     // .banner {
//     //   margin-top: 86px!important;
//     // }
//   }
// }

@media (max-width: 750px) {
  .pc {
    display: none;
  }
  .mob {
    display: block;
  }
  .banner {
    height: auto !important;
    padding: 45px 10% !important;
    // margin-top: 10px;
    h1 {
      font-size: 18px !important;
      line-height: 26px !important;
      padding: 0 !important;
      padding-bottom: 20px !important;
    }
    div {
      p {
        width: 100% !important;
        float: none !important;
        text-align: center;
        font-size: 14px !important;
        line-height: 16px !important;
        padding: 6px 0 !important;
      }
    }
  }
  .banner_bottom {
    height: auto !important;
    padding-top: 65px !important;
    padding-bottom: 45px !important;
    .con {
      width: 86%;
      padding-top: 0 !important;
      .img {
        width: 32vw !important;
        height: 34vw !important;
        max-width: 135px !important;
        max-height: 145px !important;
        float: none !important;
        margin: 0 !important;
        margin: 0 auto !important;
      }
      .text {
        padding-top: 25px !important;
        .title,
        .title * {
          font-size: 15px !important;
          font-weight: bold !important;
        }
        .title {
          line-height: 22px !important;
          text-align: center;
          img {
            height: 16px !important;
          }
        }
        .desc {
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: 24px !important;
          color: #4c4c4c !important;
        }
        .desc:nth-of-type(2) {
          padding-top: 5px !important;
          padding-bottom: 16px !important;
          text-align: center !important;
          color: #293f76 !important;
          font-size: 13px !important;
          font-family: PingFang SC medium !important;
          font-weight: 500 !important;
          margin: 0 !important;
        }
      }
    }
  }
  .course {
    padding: 40px 0 !important;
    .con {
      width: 90% !important;
      padding: 35px 20px !important;
      padding-bottom: 45px !important;
      h2 {
        font-size: 15px !important;
        padding-bottom: 20px !important;
      }
      p {
        font-size: 14px !important;
      }
    }
  }
  .other {
    padding-top: 45px !important;
    .con {
      h1 {
        padding-bottom: 24px !important;
        font-size: 18px !important;
      }
      .case_con {
        * {
          font-size: 15px !important;
        }
        ul {
          a {
            width: 90%;
            padding: 13px !important;
            .content {
              width: 100% !important;
              padding-top: 18px !important;
              img {
                width: 60px !important;
                height: 12px !important;
              }
              p {
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>