var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"course"}},[_c('div',{staticClass:"banner"},[_c('h1',[_vm._v(_vm._s(_vm.course.title))]),_c('div',{staticClass:"pc"},[_c('p',[_vm._v(" 专题："),(_vm.course.venueName && _vm.course.venueName.name)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.venueName.name))]}):_vm._e()],2),_c('p',[_vm._v(" 会议地点："),(_vm.course.venueName && _vm.course.venueName.address)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.venueName.address))]}):_vm._e()],2),_c('p',[_vm._v(" 案例来源："),(_vm.course.venueName && _vm.course.venueName.company)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.lecturer[0].company))]}):_vm._e()],2),_c('p',[_vm._v(" 分享时间："),(_vm.course.venueName && _vm.course.venueName.date)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.venueName.date))]}):_vm._e()],2)]),_c('div',{staticClass:"mob"},[_c('p',[_vm._v(" 案例来源："),(
            _vm.course.lecturer &&
            _vm.course.lecturer[0] &&
            _vm.course.lecturer[0].company
          )?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.lecturer[0].company))]}):_vm._e()],2),_c('p',[_vm._v(" 会议地点："),(_vm.course.venueName && _vm.course.venueName.address)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.venueName.address))]}):_vm._e()],2),_c('p',[_vm._v(" 分享时间："),(_vm.course.venueName && _vm.course.venueName.date)?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.course.venueName.date))]}):_vm._e()],2)])]),_c('div',{staticClass:"banner_bottom"},[_c('div',{staticClass:"con"},[(_vm.course.lecturer && _vm.course.lecturer[0] && _vm.course.lecturer[0].id)?_c('router-link',{attrs:{"to":{
          path: '/2022sh/teacher',
          name: 'TwoSHTeacher',
          query: { id: _vm.course.lecturer[0].id },
        }}},[_c('div',{staticClass:"img",style:('background:url(' +
            _vm.course.lecturer[0].thumbs +
            ') no-repeat center center;')})]):_vm._e(),_c('div',{staticClass:"text"},[_c('p',{staticClass:"title"},[(
              _vm.course.lecturer && _vm.course.lecturer[0] && _vm.course.lecturer[0].id
            )?_c('router-link',{attrs:{"to":{
              path: '/2022sh/teacher',
              name: 'TwoSHTeacher',
              query: { id: _vm.course.lecturer[0].id },
            }}},[_vm._v(_vm._s(_vm.course.lecturer[0].name))]):_vm._e(),(
              _vm.course.companythumbs != 0 &&
              _vm.course.companythumbs != '' &&
              _vm.course.companythumbs != null
            )?_c('span',[_vm._v(" | "),_c('img',{attrs:{"src":_vm.course.companythumbs}})]):_vm._e()],1),_c('p',{staticClass:"desc"},[(
              _vm.course.lecturer &&
              _vm.course.lecturer[0] &&
              _vm.course.lecturer[0].position
            )?_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.course.lecturer[0].position)+" ")]}):_vm._e()],2),(
            _vm.course.lecturer && _vm.course.lecturer[0] && _vm.course.lecturer[0].desc
          )?_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.course.lecturer[0].desc)}}):_vm._e()])],1)]),_c('div',{staticClass:"course"},[_c('div',{staticClass:"con"},[_c('h2',[_vm._v("课程概要")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.course.desc)}}),_c('h2',[_vm._v("听众收益")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.course.profit)}})])]),_c('div',{staticClass:"other"},[_c('div',{staticClass:"con"},[_c('h1',[_vm._v("相关案例")]),_c('div',{staticClass:"case_con"},[_c('SelectedCaseNew',{attrs:{"caseList":_vm.course.related}})],1),_c('div',{staticClass:"clearboth"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }